import React, { useEffect, useState } from "react";
import { Button } from "../../../../components/ui/button";
import {
  Card,
  CardContent,
  CardFooter,
  CardHeader,
  CardTitle,
} from "../../../../components/ui/card";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../../../../src/@/components/ui/table";
import { usePDF } from "react-to-pdf";
import { useNavigate, useParams, useLocation } from "react-router-dom";
// import { getPayslip } from "app/hooks/payroll";
// import RevisedSalarySheet from "./RevisedSalarySheet";
import {
  DesignationName,
  EmployeeID,
  // getExperience,
} from "utils/getValuesFromTables";
import { getEmployeeData } from "app/hooks/employee";
import { numberToWords } from "utils/renderValues.js";
import { PageLoader } from "components";
import { ArrowLeft } from "lucide-react";
import moment from "moment";
import { getPayslip } from "app/hooks/payroll";
import { getPayslipByID } from "app/hooks/payroll";
import { getFinalSettlement } from "app/hooks/payroll";


// Dummy Earnings Data
const dummyEarningsData = [
  { description: "Basic Salary", amount: 2000 },
  { description: "House Allowance", amount: 500 },
  { description: "Transport Allowance", amount: 300 },
  { description: "Medical Allowance", amount: 200 },
  { description: "Other Allowances", amount: 150 },
];

// Dummy Deductions Data
const dummyDeductionsData = [
  { description: "Tax", amount: 200 },
  { description: "Insurance", amount: 100 },
];

export default function Payslip() {
  const [employeeData, setEmployeeData] = React.useState({});
  const [filterData, setFilterData] = React.useState({});
  const [payslip, setPayslip] = useState(null);
  const [loading, setLoading] = React.useState(true);
  const [eosData, setEosData] = React.useState([]);
  const [finalSettlement, setFinalSettlement] = React.useState(null);
  const { id } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const employeeID = new URLSearchParams(location.search).get("employeeID");
  const isEos = location.pathname.startsWith("/payslip-eos");


  const fetchData = async () => {
    setLoading(true);
    const empData = await getEmployeeData(employeeID);
    if (empData) {
      setEmployeeData(empData);
    }

      const payslip = await getPayslipByID(id);
      if (payslip) {
        console.log("payslip", payslip);
        setPayslip(payslip);
      }
    setLoading(false);

    if(isEos && payslip){
      const finalSettlement = await getFinalSettlement({
        filterData: { employee_payroll: payslip.employee_payroll },
      });
      console.log("Final Settlement", finalSettlement);
      const finalSettlementData = finalSettlement.results[0];
        const eosData = [
          {
            description: "Earned Leave Encashment",
            amount: finalSettlementData.earned_leave_encashment,
          },
          {
            description: "Gratuity",
            amount: finalSettlementData.gratuity_amount,
          },
          {
            description: "Total Deductions",
            amount: finalSettlementData.total_deductions,
          },
          {
            description: "Remaining Salary",
            amount: finalSettlementData.remaining_salary,
          },
        ];
      setEosData(eosData);
      setFinalSettlement(finalSettlement.results[0]);
    }
  };
  useEffect(() => {
    fetchData();
  }, [id]);


  const { toPDF, targetRef } = usePDF({
    filename: "payslip.pdf",
    page: { margin: 5 },
  });

  console.log("payslip", eosData);

  return (
    <>
      <div className="mb-4">
        <Button
          variant="ghost"
          onClick={() => {
            navigate(-1);
          }}
          className="p-4 text-xl text-balance"
        >
          <ArrowLeft className="w-6 h-6 mr-2 bg-white rounded-lg shadow-sm" />
          Payroll Detail
        </Button>
      </div>
      <div className="container p-4 mx-auto">
        <Card ref={targetRef} className="w-full max-w-4xl mx-auto">
          <CardHeader className="text-white bg-plum-600">
            <div className="flex items-center justify-between">
              <div>
                <CardTitle className="text-2xl font-bold">PlumPro</CardTitle>
                <p>Karachi, Pakistan</p>
              </div>
              <p>www.plumpro.com</p>
            </div>
          </CardHeader>
          {loading ? (
            <PageLoader />
          ) : !payslip || payslip?.length === 0 ? (
            <div className="bg-plum-400 text-plum-900 text-center rounded-lg p-4 mt-4">
              No Payslip Data Found
            </div>
          ) : (
            <CardContent className="space-y-6">
              <h2 className="mt-4 text-xl font-semibold text-plum-900">
                Payslip for the month of{" "}
                {moment(payslip?.generated_at).format("MMMM YYYY")}
              </h2>

              <div className="grid grid-cols-2 gap-4">
                <Card>
                  <CardHeader>
                    <CardTitle>Employee Pay Summary</CardTitle>
                  </CardHeader>
                  <CardContent>
                    <Table>
                      <TableBody>
                        <TableRow>
                          <TableCell>ID</TableCell>
                          <TableCell>
                            {" "}
                            <EmployeeID value={employeeID} />
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>Name</TableCell>
                          <TableCell>{`${employeeData?.first_name} ${employeeData?.last_name}`}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>Designation</TableCell>
                          <TableCell>
                            <DesignationName
                              value={employeeData?.department_position}
                            />
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>Date of Joining</TableCell>
                          <TableCell>{employeeData?.joining_date}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>Pay Period</TableCell>
                          <TableCell>
                            {moment(payslip?.generated_at).format("MMMM YYYY")}
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>Pay Date</TableCell>
                          <TableCell>
                            {moment(payslip?.generated_at).format("DD/MM/YYYY")}
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </CardContent>
                </Card>
                <Card>
                  <CardHeader>
                    <CardTitle>Cost to Company</CardTitle>
                  </CardHeader>
                  <CardContent>
                    <div className="text-4xl font-bold text-plum-900">
                      {payslip?.net_salary} AED
                    </div>
                    <p className="mt-2 text-sm text-gray-600">
                      {numberToWords(Number(payslip?.net_salary))}
                    </p>
                    <div className="flex justify-between mt-4">
                      <div>
                        <p className="font-semibold">
                          {employeeData.daysOfWork} Days
                        </p>
                        <p className="text-sm text-gray-600">of work</p>
                      </div>
                      <div>
                        <p className="font-semibold">
                          {employeeData.absentDays} Days
                        </p>
                        <p className="text-sm text-gray-600">Absent days</p>
                      </div>
                    </div>
                  </CardContent>
                </Card>
              </div>

              <div className="grid grid-cols-2 gap-4">
                <Card>
                  <CardHeader>
                    <CardTitle>Employee Earnings</CardTitle>
                  </CardHeader>
                  <CardContent>
                    <Table>
                      <TableHeader>
                        <TableRow>
                          <TableHead>Earning types</TableHead>
                          <TableHead className="text-right">Amount</TableHead>
                        </TableRow>
                      </TableHeader>
                      <TableBody>
                        {payslip?.total_earning_types?.map((item, index) => (
                          <TableRow key={index}>
                            <TableCell>
                              <div className="capitalize">
                                {item.description}
                              </div>
                            </TableCell>
                            <TableCell className="text-right">
                              AED {Number(item.amount).toFixed(2)}
                            </TableCell>
                          </TableRow>
                        ))}
                        <TableRow className="font-bold">
                          <TableCell>Total in AED</TableCell>
                          <TableCell className="text-right">
                            AED {Number(payslip?.gross_salary).toFixed(2)}
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </CardContent>
                </Card>
                <Card>
                  <CardHeader>
                    <CardTitle>Employee Deductions</CardTitle>
                  </CardHeader>
                  <CardContent>
                    <Table>
                      <TableHeader>
                        <TableRow>
                          <TableHead>Deduction types</TableHead>
                          <TableHead className="text-right">Amount</TableHead>
                        </TableRow>
                      </TableHeader>
                      <TableBody>
                        {payslip?.total_deduction_types?.map((item, index) => (
                          <TableRow key={index}>
                            <TableCell>
                              <div className="capitalize">
                                {item.description}
                              </div>
                            </TableCell>
                            <TableCell className="text-right">
                              AED {Number(item.amount).toFixed(2)}
                            </TableCell>
                          </TableRow>
                        ))}
                        <TableRow className="font-bold">
                          <TableCell>Total in AED</TableCell>
                          <TableCell className="text-right">
                            AED{" "}
                            {Number(
                              payslip?.gross_salary - payslip?.net_salary
                            ).toFixed(2)}
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </CardContent>
                </Card>
              </div>
              {isEos && (
                <Card>
                  <CardHeader>
                    <CardTitle>End Of Service</CardTitle>
                  </CardHeader>
                  <CardContent>
                    <Table>
                      <TableHeader>
                        <TableRow>
                          <TableHead>Earning types</TableHead>
                          <TableHead className="text-right">Amount</TableHead>
                        </TableRow>
                      </TableHeader>
                      <TableBody>
                        {eosData?.map((item, index) => (
                          <TableRow key={index}>
                            <TableCell>{item.description}</TableCell>
                            <TableCell className="text-right">
                              AED {Number(item.amount).toFixed(2)}
                            </TableCell>
                          </TableRow>
                        ))}
                        <TableRow className="font-bold">
                          <TableCell>Total in AED</TableCell>
                          <TableCell className="text-right">
                            AED{" "}
                            {Number(finalSettlement?.final_amount).toFixed(2)}
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </CardContent>
                </Card>
              )}

              <Card>
                <CardHeader>
                  <CardTitle>Net Pay</CardTitle>
                </CardHeader>
                <CardContent>
                  <Table>
                    <TableBody>
                      <TableRow>
                        <TableCell>Earnings</TableCell>
                        <TableCell className="text-right">
                          AED {Number(payslip?.gross_salary)?.toFixed(2)}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Deductions</TableCell>
                        <TableCell className="text-right">
                          (-) AED{" "}
                          {Number(
                            payslip?.gross_salary - payslip?.net_salary
                          )?.toFixed(2)}
                        </TableCell>
                      </TableRow>
                      <TableRow className="font-bold">
                        <TableCell>Total in AED</TableCell>
                        <TableCell className="text-right">
                          AED {Number(payslip?.net_salary).toFixed(2)}
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </CardContent>
              </Card>

              <div className="grid grid-cols-2 gap-4">
                <Card>
                  <CardHeader>
                    <CardTitle>Comments</CardTitle>
                  </CardHeader>
                  <CardContent>
                    <div className="h-20 border rounded"></div>
                  </CardContent>
                </Card>
                <Card>
                  <CardHeader>
                    <CardTitle>Signature</CardTitle>
                  </CardHeader>
                  <CardContent>
                    <div className="h-20 border rounded"></div>
                  </CardContent>
                </Card>
              </div>
            </CardContent>
          )}
        </Card>

        {payslip && payslip.length > 0 && (
          <div className="w-full max-w-4xl mx-auto mt-4">
            <CardFooter className="flex justify-end">
              <Button onClick={() => toPDF()}>Download PDF</Button>
            </CardFooter>
          </div>
        )}
      </div>
    </>
  );
}
